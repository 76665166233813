import React, { useEffect, useMemo, useState, } from 'react'
import Toolbar from '../common/toolbar';

import './topbar.css'
import { CenterIcon, CopyIcon, CutIcon, DrawIcon, MinusIcon, PasteIcon, PlusIcon, RedoIcon, ScreenShotIcon, SelectIcon, TrashIcon, UndoIcon, LockWallsIcon } from '../common/icons/icons';
import { Button, Flex, Switch, Tooltip } from 'antd';
import { CopyShortcut, CutShortcut, DeleteShortcut, DrawWallsShortcut, LockWallsShortCut, PasteShortcut, RedoShortcut, Screenshotshortcut, SelectIconShortcut, UndoShortcut, ZoomInShortcut, ZoomOutShortcut, ZoomResetShortcut } from '../common/icons/shortcutIcons/shortcutIcons';


const BarDivider = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="26" viewBox="0 0 2 26" fill="none">
            <path opacity="0.5" d="M1 1L1 25" stroke="#7B7B7B" strokeLinecap="round" />
        </svg>
    )
}

const PercentageZoom = ({ transform, setZoom }) => {
    const handleZoom = zoomFunc => {
        setZoom(zoomFunc, window.innerWidth / 2, window.innerHeight / 2);
    };


    useEffect(() => {
        const handleKeyDownZoom = (event) => {
            if (event.ctrlKey) {
                if (event.key === '0') {
                    handleZoom(zoom => 1)
                }
            }
        };

        window.addEventListener('keydown', handleKeyDownZoom);

        return () => {
            window.removeEventListener('keydown', handleKeyDownZoom);
        };
    }, []); // Make sure to include undo and redo functions in the dependencies array

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2px'
        }}>
            <Tooltip placement="bottom" title={
                <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                    Zoom out
                    <ZoomOutShortcut />
                </span>
            }>
                <Button icon={<MinusIcon />} type="text" iconPosition={"center"} onClick={() => handleZoom(zoom => zoom / 1.1)} />
            </Tooltip>
            <span style={{ width: 'auto', height: 'auto', fontSize: '11px' }}> {Math.floor(transform.scale * 100)}% </span>
            <Tooltip placement="bottom" title={
                <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                    Zoom in
                    <ZoomInShortcut />
                </span>
            }>
                <Button icon={<PlusIcon />} type="text" iconPosition={"center"} onClick={() => handleZoom(zoom => zoom * 1.1)} />
            </Tooltip>
            <Tooltip placement="bottom" title={
                <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                    Zoom reset to %100
                    <ZoomResetShortcut />
                </span>
            }>
                <Button icon={<CenterIcon />} type="text" onClick={() => handleZoom(zoom => 1)} />
            </Tooltip>
        </div>
    );
};





export default function Topbar({ mode, setMode, deleteItems, undo, redo, cut, copy, paste, copyItem, selectedItem, captureScreenshot, transform, setZoom, setShowMeasurements, selectedWall, setDeleteState, selectedItemIndexes, lockedWallsState, setLockedWallsState }) {
    // Hooks
    const [arrow, setArrow] = useState('Show');
    const [copied, setCopied] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const buttonStyle = (selectedMode) => ({
        backgroundColor: selectedMode === mode ? '#bfa1ff' : 'transparent',
        borderRadius: '4px', // Optional: add a border radius for a button-like appearance
        border: '1px solid transparent', // Optional: keep consistent button structure
        transition: 'background-color 0.3s ease', // Smooth transition for background change
    });

    // Hover effect styling
    const buttonHoverStyle = (selectedMode) => ({
        backgroundColor: selectedMode === mode ? '#bfa1ff' : '#f0f0f0', // Slight change on hover
        color: selectedMode === mode ? '#fff' : '#000',
        cursor: 'pointer',
    });

    const handleUndo = () => {
        undo();
    }

    const handleRedo = () => {
        redo();
    };

    const handleCut = () => {
        if (selectedItem) {
            setCopied(true);
            cut(selectedItem);
        }

    }

    const handleCopy = () => {
        if (selectedItem) {
            setCopied(true);
            copy(selectedItem);
        }
    }

    const handlePaste = () => {
        setCopied(false);
        paste();
    }

    const handleDelete = () => {
        if (selectedItem) {
            deleteItems();
        }
        else if (selectedWall !== null) {
            setDeleteState(true)
        }
        else {
            console.log("Please select an item first");
        }
        
    }
    const handleLockWalls = () => {
        if(lockedWallsState)
            setLockedWallsState(false);
        else
            setLockedWallsState(true);
    }
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }

        if (arrow === 'Show') {
            return true;
        }
    });



    useEffect(() => {
        const handleKeyDownSS = (event) => {
            if (event.ctrlKey) {
                if (event.key === 'q' || event.key === 'Q') {
                    captureScreenshot();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDownSS);

        return () => {
            window.removeEventListener('keydown', handleKeyDownSS);
        };
    }, [undo, redo]); // Make sure to include undo and redo functions in the dependencies array

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.ctrlKey || event.metaKey) {
                if (event.key === 'c' || event.key === 'C') {
                    handleCopy();
                }
                else if (event.key === 'x' || event.key === 'X') {
                    handleCut();
                }
                else if (event.key === 'v' || event.key === 'V') {
                    handlePaste();

                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [copyItem, selectedItem, selectedItemIndexes, transform]);

    useEffect(() => {
        // Function to handle window resize and update screenWidth state
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });



    return (
        <Toolbar>
            <Flex justify='space-evenly' align='center' gap={screenWidth > 1024 ? '0.48vw' : '0.18vw'}>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        Select
                        <SelectIconShortcut />
                    </span>
                } arrow={mergedArrow}>
                    <Button
                        icon={<SelectIcon />}
                        style={buttonStyle("Hand")}
                        type="text"
                        iconPosition={"center"}
                        onClick={() => setMode("Hand")}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle("Hand").backgroundColor}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = buttonStyle("Hand").backgroundColor}
                    />
                </Tooltip>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        Draw Line
                        <DrawWallsShortcut />
                    </span>
                } arrow={mergedArrow}>
                    <Button 
                        icon={<DrawIcon />} 
                        type="text" 
                        style={buttonStyle("Draw")} 
                        iconPosition={"center"} 
                        onClick={() => setMode("Draw")}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle("Draw").backgroundColor}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = buttonStyle("Draw").backgroundColor}
                    />

                </Tooltip>
                <BarDivider />
                {/* Undo, Redo */}
                <Tooltip
                    placement="bottom"
                    title={
                        <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                            Undo
                            <UndoShortcut />
                        </span>
                    }
                    arrow={mergedArrow}
                >
                    <Button icon={<UndoIcon />} type="text" iconPosition={"center"} onClick={() => handleUndo()} />
                </Tooltip>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        Redo
                        <RedoShortcut />
                    </span>
                } arrow={mergedArrow}>
                    <Button icon={<RedoIcon />} type="text" iconPosition={"center"} onClick={() => handleRedo()} />
                </Tooltip>
                <BarDivider />
                {/*Cut,Copy, Paste */}
                <Tooltip
                    placement="bottom"
                    title={
                        <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                            Cut
                            <CutShortcut />
                        </span>
                    }
                    arrow={mergedArrow}
                >
                    <Button icon={<CutIcon disabled={copied || !selectedItem} />} type="text" iconPosition={"center"} onClick={handleCut} disabled={copied || !selectedItem} style={{
                        cursor:'pointer'
                    }} />
                </Tooltip>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        Copy
                        <CopyShortcut />
                    </span>
                } arrow={mergedArrow}>
                    <Button icon={<CopyIcon disabled={copied || !selectedItem}/>} type="text" iconPosition={"center"} onClick={handleCopy} disabled={copied || !selectedItem} style={{
                        cursor:'pointer'
                    }}/>
                </Tooltip>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        Paste
                        <PasteShortcut />
                    </span>
                } arrow={mergedArrow}>
                    <Button icon={<PasteIcon disabled={!copied} />} type="text" iconPosition={"center"} onClick={handlePaste} disabled={!copied} style={{
                        transition: '0.3s ease',
                        cursor:'pointer'
                    }} />
                </Tooltip>
                <BarDivider />
                
                {/* Lock Walls */}
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        Lock Walls
                    </span>
                } arrow={mergedArrow}>
                    <Button icon={<LockWallsIcon lockedWallsState={lockedWallsState} />}
                    type="text" iconPosition={"center"} 
                    onClick={handleLockWalls} 
                    style={{
                        cursor:'pointer'
                        
                    }}
                    />
                </Tooltip>
                {/* Garbage Can */}
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        Delete
                        <DeleteShortcut />
                    </span>
                } arrow={mergedArrow}>
                    <Button icon={<TrashIcon disabled={(selectedItem === null) &&  selectedWall === undefined} />} 
                    type="text" iconPosition={"center"} 
                    onClick={handleDelete} 
                    disabled={selectedItem === null && selectedWall === undefined} 
                    style={{
                        cursor:'pointer'
                    }}
                    />
                </Tooltip>
                <BarDivider />
                {/* ScreenShot*/}
                {screenWidth > 1000  && <Tooltip placement="bottom"
                    title={
                        <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                            ScreenShot
                            <Screenshotshortcut />
                        </span>
                    } arrow={mergedArrow}>
                    <Button icon={<ScreenShotIcon />} type="text" iconPosition={"center"} onClick={captureScreenshot} />
                </Tooltip>}
                {screenWidth > 1000 && <BarDivider />}
                {/* Zoom Bar */}
                <PercentageZoom transform={transform} setZoom={setZoom} />
                <BarDivider />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.3vw'
                }}>
                    <span>{screenWidth > 1180 ?  "Measurements" : "" }</span>
                    <Switch onChange={setShowMeasurements} size={screenWidth > 1180 ? 'medium' : 'small'} />
                </div>
            </Flex>
        </Toolbar>
    )
}
